<template>
  <div id="printMe">
    <div class="container ml-5">
      <!-- Header -->
      <div class="header">
        <b-row>
          <b-col class="d-flex align-items-center">
            <div class="logo">
              <img
                src="media/logos/vdd_hori_logo.png"
                alt="vdd-logo"
              />
            </div>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-center">
            <h2 class="font-size-large title">BIÊN NHẬN <i class="font-weight-bold text-primary fs-13 d-block">(CUSTOMER RECEIPT)</i></h2>
          </b-col>
          <b-col>
            <div class="order-info text-start">
              <h3 class="text-dark">{{ orderData.storeName }} </h3>
              <p class="font-size-print wp-80">{{orderData.storeAddress}}</p>
              <p class="font-size-print">Tra cứu tiến độ sửa chữa: <br> www.viendidong.com</p>
              <p class="font-size-print font-weight-bold"><span class="text-bold-500">Hotline:</span> 18006729</p>
              <p class="font-size-print"><span class="text-bold-500">Hotline phản hồi chất lượng dịch vụ:</span> <br> 0977373137</p>
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- Thông tin công ty - khách hàng -->
      <div class="information">
        <div class="d-flex">
          <div class="customer border-right">
            <h3 class="font-weight-bold">Thông tin khách hàng / <i class="text-primary"> Customer Data </i> </h3>
            <table>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Tên khách hàng</p>
                  <i class="font-size-print text-primary">(Name)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.customerName }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Địa chỉ</p>
                  <i class="font-size-print text-primary">(Address)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.customerAddress }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Điện thoại</p>
                  <i class="font-size-print text-primary">(Phone number)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.customerMobile }}</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="company border-right">
            <h3>Thông tin sản phẩm / <i class="font-weight-bold text-primary"> Data Products </i> </h3>
            <table>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Loại máy</p>
                  <i class="font-size-print text-primary">(Model)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.selectedModel }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Số IMEI/ SN</p>
                  <i class="font-size-print text-primary">(MEI/ SN number)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.imei }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Tình trạng bảo hành</p>
                  <i class="font-size-print text-primary">(Warranty Status)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.selectWarranty }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Mật khẩu máy</p>
                  <i class="font-size-print text-primary">(Password)</i>
                </th>
                <td>
                  <p class="font-size-print">
                    {{orderData.password || 0}}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div class="customer">
            <h3>Thông tin nhận máy / <i class="font-weight-bold text-primary"> Receipt Info </i> </h3>
            <table>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Số biên nhận</p>
                  <i class="font-size-print text-primary">(Receipt Number)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.id }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Ngày nhận</p>
                  <i class="font-size-print text-primary">(Date Received)</i>
                </th>
                <td class="font-size-print">
                  {{orderData.checkDate}}
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Người nhận</p>
                  <i class="font-size-print text-primary">(Received By)</i>
                </th>
                <td>
                  <p class="font-size-print">{{ orderData.applicant }}</p>
                </td>
              </tr>
              <tr>
                <th class="wp-35">
                  <p class="font-size-print">Kỹ thuật viên kiểm tra</p>
                  <i class="font-size-print text-primary">(Technican)</i>
                </th>
                <td>
                  <p class="font-size-print">
                    {{orderData.technicalStaffCheckName}}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="faultdescription border-right">
          <h3>Thông tin lỗi của máy / <i class="font-weight-bold text-primary"> Fault Description </i> </h3>
          <table>
            <tr>
              <th class="wp-20">
                <p class="font-size-print">Lỗi của máy</p>
                <i class="font-size-print text-primary">(Symptom)</i>
              </th>
              <td>
                <p class="font-size-print">
                  <span
                    class="d-block"
                    :key="symptomCode.id"
                    v-for="symptomCode in orderData.selectedSymptomCode"
                  >{{symptomCode.detail}}</span>
                </p>
              </td>
            </tr>
            <tr v-if="orderData.symptomCodeDetails">
              <th class="wp-20">
                <p class="font-size-print">Chi tiết lỗi</p>
                <i class="font-size-print text-primary">(Symptom Detail)</i>
              </th>
              <td>
                <p class="font-size-print white-space">
                  {{orderData.symptomCodeDetails}}
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div class="repairfee">
          <h3>Phí sửa chữa</h3>
          <table>
            <tr>
              <th class="wp-35">
                <p class="font-size-print">Tạm tính</p>
              </th>
              <td>
                <p class="font-size-print">
                  {{convertPrice(orderData.totalAmount)|| 0}}
                </p>
              </td>
            </tr>
            <tr>
              <th class="wp-35">
                <p class="font-size-print">Tiền cọc</p>
              </th>
              <td>
                <p class="font-size-print">
                  {{convertPrice(orderData.depositAmount) || 0}}
                </p>
              </td>
            </tr>
            <tr>
              <th class="wp-35">
                <p class="font-size-print">Phí được thu</p>
                <i class="font-size-print text-primary">(Charged)</i>
              </th>
              <td>
                <p class="font-size-print">
                  {{orderData.totalAmount !== 0 ? 'Có' : 'Không'}}
                </p>
              </td>
            </tr>
          </table>
        </div>
        <!-- <div class="company border-right">
          <h3>Kỹ thuật viên kiểm tra</h3>
          <table>
            <tr>
              <th class="wp-35">
                <p class="font-size-print">Họ và Tên</p>
              </th>
              <td>
                <p class="font-size-print">
                  {{orderData.technicalStaffCheckName}}
                </p>
              </td>
            </tr>
          </table>
        </div> -->
        <div class="customer">
          <h3>Hiện trạng máy</h3>
          <table>
            <tr>
              <th class="wp-29">
                <p class="font-size-print">Hiện trạng máy</p>
              </th>
              <td>
                <p class="font-size-print">
                  {{getDeviceInterface}}
                </p>
              </td>
            </tr>
            <tr v-if="orderData.deviceInterfaceDetail ">
              <th class="wp-29">
                <p class="font-size-print">Chi tiết hiện trạng</p>
              </th>
              <td>
                <p class="font-size-print white-space">
                  {{orderData.deviceInterfaceDetail}}
                </p>
              </td>
            </tr>
            <tr>
              <th class="wp-29">
                <p class="font-size-print">Ngày trả</p>
                <i class="font-size-print text-primary">(Return date)</i>
              </th>
              <td></td>
            </tr>
            <tr>
              <th class="wp-29">
                <p class="font-size-print">Ngày in</p>
                <i class="font-size-print text-primary">(Printed date)</i>
              </th>
              <td>
                <p class="font-size-print">
                  {{formatPrintedDate}}
                </p>
              </td>
            </tr>
          </table>
        </div>

      </div>
      <!-- Danh sách sản phẩm -->
      <!-- <div class="products">
        <table>
          <thead>
            <tr>
              <th
                class="text-center"
                style="width: 5%"
              >
                <span class="font-size-print">STT</span>
              </th>
              <th>
                <span class="font-size-print">Tên sản phẩm</span>
              </th>
              <th><span class="font-size-print">SL</span></th>
              <th class="text-right"><span class="font-size-print">Đơn giá</span></th>
              <th class="text-right"><span class="font-size-print">CK</span></th>
              <th class="text-right">
                <span class="font-size-print">Thành tiền</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(product, index) in orderData.orderItems">
              <tr :key="product.id">
                <td class="text-center">
                  <span
                    class="font-size-print text-bold-500"
                    v-if="product.orderItemType === 1"
                  >{{ index + 1 }}</span>
                </td>
                <td>
                  <span
                    class="font-size-print"
                    v-if="product.orderItemType === 2"
                  ><i
                      data-v-f69b43ce=""
                      class="fas fa-gift ml-4"
                      style="color: rgb(24, 28, 50);"
                    ></i> {{ product.productName }}</span>
                  <span
                    v-else
                    class="font-size-print"
                  >{{ product.productName }}</span>
                </td>
                <td class="text-center">
                  <span class="font-size-print">{{ product.quantity }}</span>
                </td>
                <td class="text-right">
                  <span class="font-size-print">{{ convertPrice(product.productPrice) }}</span>
                </td>
                <td class="text-right">
                  <span class="font-size-print">{{ convertPrice(product.discountAmount) }}</span>
                </td>
                <td class="text-right">
                  <span
                    class="font-size-print"
                    v-if="product.orderItemType === 1"
                  >{{ convertPrice(product.totalAmount) }}</span>
                  <span
                    v-else
                    class="font-size-print"
                  >0</span>
                </td>
              </tr>
            </template>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="2">
                <div>
                  <span class="font-size-print text-bold-500">Số tiền đã thanh toán:</span>
                  <span class="font-size-print"> {{ convertPrice(orderData.sumPayment) }}</span>
                </div>
                <div>
                  <span class="font-size-print text-bold-500">Số tiền còn lại phải thanh toán:</span>
                  <span class="font-size-print"> {{ convertPrice(orderData.totalAmountToPay) }}</span>
                </div>
                <div>
                  <span class="font-size-print text-bold-500">Tổng chiết khấu:</span>
                  <span class="font-size-print"> {{ convertPrice(orderData.discountAmount) }}</span>
                </div>
              </td>
              <td class="text-center">
                <span class="font-size-print">{{ orderData.totalQuantity }}</span>
              </td>
              <td class="text-right">
                <span class="font-size-print">{{ convertPrice(orderData.totalUnitPrice) }}</span>
              </td>
              <td class="text-right">
                <span class="font-size-print">{{ convertPrice(orderData.totalDiscountAmount) }}</span>
              </td>
              <td class="text-right">
                <span class="font-size-print text-bold-500"> {{ convertPrice(orderData.totalAmount) }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <p class="font-size-print"><span class="text-bold-500">Bằng chữ:</span> {{ convertNumberToText(orderData.totalAmount) }}</p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div> -->
      <!-- Ghi chú -->
      <div class="note">
        <h3>Quy định sữa chữa</h3>
        <table>
          <tr>
            <td>
              <p class="text-dark">
                1. Công ty không chịu trách nhiệm về dữ liệu trên máy khách hàng, khách hàng phải tự lưu lại <br>
                2. Khi nhận máy phải có phiếu biên nhận <br>
                3. Biên nhận có giá trị trong 3 tháng kể từ ngày nhận máy, bao gồm máy không sửa được <br>
                4. Thời gian bảo hành kể từ ngày khách hàng nhận lại máy và được áp dụng cho cùng lỗi và cùng linh kiện <br>
                5. Chúng tôi chỉ bảo hành những phần đã sửa chữa <br>
                6. Trường hợp: Mất nguồn/ Treo Logo/ Không lên màn hình chúng tôi không chịu trách nhiệm trên các chức năng
                khác <br>
                <b>7. Khi ký biên nhận này là khách hàng đã đồng ý với các điều kiện trên</b>
              </p>
            </td>
          </tr>
        </table>
        <b>
          Thời gian làm việc: 8h - 21h <br>
          Quý khách vui lòng mang theo biên nhận khi đến nhận lại máy. Xin cảm ơn</b>
      </div>
      <!-- Kí kết -->
      <div class="signing">
        <div class="sign-customer">
          <span class="font-size-print text-bold-500">Khách hàng</span>
          <table>
            <tr class="title-sign-customer">
              <th>Gửi máy / in</th>
              <th>Trả máy / out</th>
            </tr>
          </table>
        </div>
        <div class="sign-sale">
          <span class="font-size-print text-bold-500">Nhân viên bán hàng</span>
          <table>
            <tr class="title-sign-customer">
              <th>Gửi máy / in</th>
              <th>Trả máy / out</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/core/services/event-bus';
import { convertPrice, convertNumberToText } from '@/utils/common';
import { DEVICE_INTERFACE, ACCESSORY_NAME } from '@/utils/constants';
import { reduceDataTreeSelect } from '@/utils/common';
import moment from 'moment';

export default {
  props: ['payload'],
  components: {},
  created() {
    this.mappingEnum();
  },
  mounted() {
    EventBus.$on('order-fetched', (data) => {
      this.orderData = data;
    });
  },
  data() {
    return {
      convertNumberToText,
      convertPrice,
      orderData: {},
      deviceExtropections: DEVICE_INTERFACE,
      accessoryName: ACCESSORY_NAME,
    };
  },
  methods: {
    mappingEnum() {
      this.deviceInterface.optionDeviceInterface = reduceDataTreeSelect(
        this.orderData.deviceInterface,
        'id',
        'name',
      );
    },
  },
  computed: {
    getDeviceInterface() {
      const statusIds = JSON.parse(this.orderData.deviceInterfaceId);
      let template = '';
      for (const statusId of statusIds) {
        const foundStatus = this.deviceExtropections.find(
          (extrospection) => extrospection.id === statusId,
        );
        console.log(foundStatus);
        template += `${foundStatus.name},`;
      }
      return template;
    },
    formatPrintedDate() {
      return moment(Date.now()).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style lang="css" scoped>
.white-space {
  white-space: pre-line;
}
/* max with */
.mx-70 {
  max-width: 67% !important;
}
/* with by percent */
.wp-20 {
  width: 20%;
}
.wp-29 {
  width: 29%;
}
.wp-35 {
  width: 35%;
}
.wp-80 {
  width: 80%;
}
/* font size */
.fs-13 {
  font-size: 13px;
}
.fs-17 {
  font-size: 17px;
  color: var(--primary-color);
}
.fs-18 {
  font-size: 18px;
}
.title-sign-customer {
  vertical-align: top;
  height: 80px !important;
}
/* ============ common ============ */
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}
.container {
  --primary-color: #000000;
  position: relative;
  height: 100%;
}

.container::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 96%;
  opacity: 0.2;
  background-image: url(/media/logos/vdd_hori_logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-size-large {
  font-size: 26px;
  color: var(--primary-color);
}

.font-size-print {
  color: var(--primary-color);
  font-size: 14px;
}

.text-bold-500 {
  font-weight: 500;
}

.text-bold-400 {
  font-weight: 400;
}

/* ============ heading ============ */
.header {
  margin-top: 30px;
  margin-bottom: 20px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header .logo {
  flex: 1;
}

.header .order-info {
  flex: 1;
  display: inline-block;
  text-align: start;
}

.header .order-info .order-detail {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.logo img {
  object-fit: contain;
  max-width: 100%;
}
/* ============ note =================== */
.note {
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 0px 10px;
}

/* ============ title ================== */

.title {
  margin: 0;
  padding: 0;
  line-height: 25px;
  transform: translate(-5px, 15px);
}

/* ============ order info ============ */
.order-info {
  display: flex;
  justify-content: center;
  line-height: 16px;
  text-align: start;
  margin-left: 0px;
}

/* ============ information ============ */
.information {
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.information .faultdescription {
  flex-basis: calc(73% - 25px);
  border: transparent;
  border-radius: 0;
  padding: 10px;
}

.information .faultdescription table,
th,
td {
  border: none !important;
}

.information .repairfee {
  flex-basis: calc(25% - 25px);
  border: none;
  border-radius: 0;
  transform: translateX(-10px);
}

.information .repairfee table,
th,
td {
  border: none !important;
}

.information .company {
  flex-basis: calc(70% - 20px);
  border: transparent;
  border-radius: 0;
  padding: 10px;
}

.information .company table,
th,
td {
  border: none !important;
}

.information .customer {
  flex-basis: 50%;
  border: transparent;
  border-radius: 0;
  padding: 10px;
}
.information .customer table,
th,
td {
  border: none !important;
}

.information p {
  margin-bottom: 0px;
  line-height: 1.4;
}

.products {
  margin-top: 20px;
}

table {
  width: 100%;
}

table,
th,
td {
  border: 1px solid black;
  padding: 2px;
  vertical-align: text-top;
}

.signing {
  display: flex;
  height: 170px;
  margin-top: 20px;
  gap: 20px;
  width: 98%;
  margin: 0 auto;
}

.signing table {
  width: 90%;
  margin: 0 auto;
  height: 80%;
}

.sign-customer,
.sign-sale,
.sign-accountant {
  flex: 30%;
  text-align: center;
}

.sign-customer::after,
.sign-sale::after,
.sign-accountant::after {
  content: '(Ký, họ tên)';
  display: inherit;
  font-style: italic;
  font-size: 16px;
}
/* Ghi chú */
.note {
  margin-top: 20px;
}
.note table {
  width: auto;
}
/* Footer */
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

footer p {
  margin-bottom: 0;
  color: #fff !important;
}

.border-right {
  border-right: 1px solid black !important;
}
</style>
